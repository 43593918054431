import React, {Fragment, useContext, useEffect, useMemo, useRef, useState} from "react";
import {MeetingProvider, useMeeting, useParticipant,} from "@videosdk.live/react-sdk";
import ReactPlayer from "react-player";
import cn from "classnames"
import tinycolor from "tinycolor2"
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {SocketContext, SocketProvider} from "./contexts/SocketContext";
import {getTemplate} from "./services/studioApi";

Sentry.init({
  dsn: "https://b123913a357f4fc1b54a443767753d9b@o552363.ingest.sentry.io/6659493",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0,
});

const ParticipantAudioPlayer = ({participantId}) => {
  const {micOn, micStream, isLocal} = useParticipant(participantId);
  const audioPlayer = useRef();

  const location = window.location;
  const urlParams = new URLSearchParams(location.search);
  const viewerId = urlParams.get('viewerId')

  useEffect(() => {
    if (!isLocal && audioPlayer.current && micOn && micStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(micStream.track);

      audioPlayer.current.srcObject = mediaStream;
      audioPlayer.current.play().catch((err) => {
        console.log('audioPlayer.current.play', err)
      });
    } else {
      audioPlayer.current.srcObject = null;
    }
  }, [micStream, micOn, isLocal, participantId]);

  return <audio autoPlay playsInline controls={false} ref={audioPlayer} muted={viewerId === participantId} />;
};

const ParticipantsAudioPlayer = () => {
  const mMeeting = useMeeting();

  const participants = mMeeting?.pinnedParticipants;

  return participants ? (
    <Fragment>
      {
        [...participants.keys()].map((participantId) => {
          return (
            <ParticipantAudioPlayer
              key={`participant_audio_${participantId}`}
              participantId={participantId}
            />
          )
        })
      }
    </Fragment>
  ) : (
    <></>
  );
};

const VideoComponent = ({participantId}) => {
  const {webcamStream, webcamOn, setQuality} = useParticipant(participantId);

  useEffect(() => {
    setQuality("high")
  }, [])

  const videoStream = useMemo(() => {
    if (webcamOn && webcamStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(webcamStream.track);
      return mediaStream;
    }
  }, [webcamStream, webcamOn]);

  if (!webcamOn) {
    return (
      <div className="no-camera-user">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
             stroke="currentColor" strokeWidth="2">
          <path strokeLinecap="round" strokeLinejoin="round"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
        </svg>
      </div>
    )
  }

  return (
    <div>
      <ReactPlayer
        //
        playsinline // very very imp prop
        pip={false}
        light={false}
        controls={false}
        muted={true}
        playing={true}
        url={videoStream}
        width={"100%"}
        height={"100%"}
        onError={(err) => {
          console.error(err, "participant video error");
        }}
      />
    </div>
  );
};

const ScreenShareComponent = ({participantId}) => {
  const {screenShareStream, screenShareOn} = useParticipant(participantId);

  const videoStream = useMemo(() => {
    if (screenShareOn && screenShareStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(screenShareStream.track);
      return mediaStream;
    }
  }, [screenShareStream, screenShareOn]);

  return (
    <div>
      {screenShareOn && (
        <ReactPlayer
          //
          playsinline // very very imp prop
          pip={false}
          light={false}
          controls={false}
          muted={true}
          playing={true}
          url={videoStream}
          width={"100%"}
          height={"100%"}
          onError={(err) => {
            console.error(err, "participant video error");
          }}
        />
      )}
    </div>
  );
};

const NameBadge = ({type, name, backgroundColor}) => {
  const textColor = tinycolor(backgroundColor).isDark() ? "#fff" : "#000"
  return (
    <div className="name-badge-container">
      <div className="name-badge"
           style={{backgroundColor: backgroundColor || undefined, color: textColor || undefined}}>
        {name}
      </div>
    </div>
  )
}


const screenSharingTypes = [
  "vertical",
  "horizontal"
]

const logoPositions = [
  "top-right",
  "bottom-right",
  "bottom-left",
  "top-left"
]

const MeetingContainer = () => {

  let _screenSharingParticipantId = null
  const mMeetingRef = useRef();
  const {connect, disconnect} = useContext(SocketContext);

  const [screenSharing, setScreenSharing] = useState(false)
  const [screenSharingParticipantId, setScreenSharingParticipantId] = useState(null)

  const [screenSharingType, setScreenShareType] = useState(screenSharingTypes[0])
  const [screenSharingTypeReverse, setScreenShareTypeReverse] = useState(false)

  const [backgroundColor, setBackgroundColor] = useState("#111827")
  const [backgroundImage, setBackgroundImage] = useState(null)
  const [brandingColor, setBrandingColor] = useState("#4B5563")

  const [logo, setLogo] = useState(null)
  const [logoPosition, setLogoPosition] = useState(logoPositions[0])

  const [overlayImage, setOverlayImage] = useState(null)

  const _handlePresenterChanged = (presenterId) => {
    if (!presenterId) {
      setScreenSharing(false)
      setScreenSharingParticipantId(null)
      _screenSharingParticipantId = null
      return
    }

    const participants = mMeetingRef.current?.participants;
    const mPresenter = participants.get(presenterId);
    setScreenSharingParticipantId(presenterId)
    _screenSharingParticipantId = presenterId
    if (mPresenter) {
      const presenterPinState = mMeetingRef.current.participants.get(presenterId).pinState
      if (presenterPinState.share) {
        setScreenSharing(true)
      }
    }
  }

  const _handlePinStateChanged = ({participantId, state}) => {
    if (_screenSharingParticipantId === participantId) {
      if (state.share) {
        setScreenSharing(true)
        return
      }
      setScreenSharing(false)
    }
  }

  const meeting = useMeeting({
    onPresenterChanged: _handlePresenterChanged,
    onPinStateChanged: _handlePinStateChanged,
  });

  useEffect(() => {
    mMeetingRef.current = meeting;
  }, [meeting]);

  useEffect(() => {
    return () => {
      const urlParams = new URLSearchParams(window.location.search);
      disconnect(`TemplateChannel.${urlParams.get("event_id")}.${urlParams.get("track_id")}`)
    }
  }, [])

  const getDisplayName = (participantId) => {
    return meeting?.participants?.get(participantId) ? meeting?.participants?.get(participantId).displayName : ""
  }

  const _pinnedParticipants = meeting?.pinnedParticipants

  useEffect(() => {
    initSocket().then()
    getTemplate().then(data => {
      setTemplateData(data)
    })
  }, [])

  const initSocket = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const templateChannel = await connect(`TemplateChannel.${urlParams.get("event_id")}.${urlParams.get("track_id")}`)
      templateChannel.bind('updated', (data) => {
        setTemplateData(data)
      })
    } catch (err) {
      console.error(err)
    }
  }

  const setTemplateData = (data) => {
    const {background, branding, logo, layout, overlay} = data
    setBackgroundColor(background.color)
    setBackgroundImage(background.image || null)
    setBrandingColor(branding.color)
    setLogo(logo.image)
    setLogoPosition(logo.position)
    setScreenShareType(layout.type)
    setScreenShareTypeReverse(layout.reverse)
    setOverlayImage(overlay.image)
  }

  const pinnedParticipants = useMemo(() => {
    const participantKeys = Array.from(_pinnedParticipants.keys())
    return participantKeys.map(participantId => {
      const pinState = meeting?.pinnedParticipants.get(participantId)
      if (pinState.share && !screenSharing) {
        _handlePresenterChanged(participantId)
        _handlePinStateChanged({participantId, state: pinState})
      }
      if (pinState.share && !pinState.cam) {
        return null
      }
      return {
        participantId: participantId,
      }
    }).filter(i => i)
  }, [_pinnedParticipants])

  const participantCount = pinnedParticipants.length

  return meeting.isMeetingJoined ? (
    <>
      <ParticipantsAudioPlayer />
      <div className={cn(
        "mc",
        "mc-bg",
        !screenSharing ? "smc" : "mc-ss",
        !screenSharing && participantCount === 2 && "smc-two-user",
        !screenSharing && participantCount === 3 && "smc-three-user",
        !screenSharing && participantCount === 4 && "smc-four-user",
        !screenSharing && participantCount === 1 && "smc-one-user",
        !screenSharing && participantCount === 5 && "smc-five-user",
        !screenSharing && participantCount === 6 && "smc-six-user",
        !screenSharing && participantCount === 7 && "smc-seven-user",
        !screenSharing && participantCount === 8 && "smc-eight-user",
        !screenSharing && participantCount === 9 && "smc-nine-user",
        screenSharing && screenSharingType === "vertical" && "mc-ss-vertical",
        screenSharing && screenSharingType === "horizontal" && "mc-ss-horizontal",
        screenSharing && screenSharingTypeReverse && "mc-ss-reverse",
      )}
           style={{
             backgroundImage: backgroundImage ? `url(${backgroundImage})` : undefined,
             backgroundColor: backgroundColor || undefined
           }}
      >
        {logo && (
          <img className={cn(
            "logo",
            `logo-${logoPosition}`
          )} src={logo} alt="logo"/>
        )}
        {
          screenSharing && screenSharingParticipantId ? (
            <>
              <div className={"screen-sharing"}>
                <ScreenShareComponent participantId={screenSharingParticipantId}/>
                <NameBadge name={getDisplayName(screenSharingParticipantId) + "'s Screen"}
                           type={"sharing"} backgroundColor={brandingColor}/>
              </div>
              <div className="sidebar">
                {pinnedParticipants.map(({participantId}) => {
                  return (
                    <div className={"camera"} key={participantId}>
                      <VideoComponent participantId={participantId}/>
                      <NameBadge name={getDisplayName(participantId)}
                                 type={"camera"} backgroundColor={brandingColor}/>
                    </div>
                  )
                })}
              </div>
            </>
          ) : (
            <>
              {pinnedParticipants.map(({participantId}) => {
                return (
                  <div className={"camera"} key={participantId}>
                    <VideoComponent participantId={participantId}/>
                    <NameBadge name={getDisplayName(participantId)}
                               type={"camera"} backgroundColor={brandingColor}/>
                  </div>
                )
              })}
            </>
          )
        }
      </div>
      {overlayImage && (
        <div className="overlay">
          <img src={overlayImage} alt={""}/>
        </div>
      )}
    </>
  ) : (
    <div></div>
  );
};

export default function App() {
  const {meetingId, token, participantId} = useMemo(() => {
    const location = window.location;

    const urlParams = new URLSearchParams(location.search);

    let paramKeys = {
      meetingId: "meetingId",
      token: "token",
      participantId: "participantId",
    };


    Object.keys(paramKeys).forEach((key) => {
      paramKeys[key] = urlParams.get(key)
        ? decodeURIComponent(urlParams.get(key))
        : null;
    });

    paramKeys = {
      ...paramKeys,
      participantId: paramKeys.participantId || "recorder",
      token: paramKeys.token || "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiIyYzdiMWFkYy0xNTcxLTQ3MjQtYWViOS0wYmI1NDg5MjZiYmQiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIiwiYWxsb3dfbW9kIl0sImlhdCI6MTY1ODMwMzYwMiwiZXhwIjoxNjU5MTY3NjAyfQ.kIlHIDqasFfl00qqdIjeIB3aazwfQ1vIZgP2jjSPaTE",
      meetingId: paramKeys.meetingId,
    }

    return paramKeys;
  }, []);

  return meetingId && token && participantId ? (
    <div>
      <SocketProvider>
        <MeetingProvider
          config={{
            meetingId,
            micEnabled: false,
            webcamEnabled: false,
            hideLocalParticipant: true,
            name: participantId,
            participantId,
          }}
          token={token}
          joinWithoutUserInteraction
        >
          <MeetingContainer/>
        </MeetingProvider>
      </SocketProvider>
    </div>
  ) : null;
}
