import axios from "axios";

const api = axios.create({
  baseURL: "https://klive-api.kommunity.com"
})

const urlParams = new URLSearchParams(window.location.search);

export const getTemplate = async () => {
  const params = {
    "event_id": urlParams.get("event_id"),
    "track_id": Number(urlParams.get("track_id")),
  }
  try {
    const req = await api.get("/api/v1/studio/templates", {params})
    return req.data
  } catch (err) {
    console.log(err)
  }
}
