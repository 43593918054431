import React, {createContext, useEffect, useMemo} from "react"
import Pusher from "pusher-js"

export const SocketContext = createContext({})

export const SocketProvider = ({children}) => {

  const pusherOptions = {
    forceTLS: true,
    wsHost: 'ws.kommunity.com',
    encrypted: true,
  }

  const pusher = useMemo(() => new Pusher("8em20p8bsrlyaj214w7t", pusherOptions), [pusherOptions]);
  const channels = {}

  const connect = async (channelName) => {
    return new Promise((resolve) => {
      if(channels[channelName]) return resolve(channels[channelName])
      channels[channelName] = pusher.subscribe(channelName)
      resolve(channels[channelName])
    })
  }

  const disconnect = (channels = []) => {
    channels.forEach(channelName => {
      pusher.unsubscribe(channelName)
    })
  }

  useEffect(() => {
    return () => {
      disconnect(Object.keys(channels))
    };
  }, []);

  return (
    <SocketContext.Provider value={{connect, disconnect}}>
      {children}
    </SocketContext.Provider>
  )
}
